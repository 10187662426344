<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Home',

    metaInfo () {
      return {
        title: this.$t('hero.title'),
        titleTemplate: (titleChunk) => {
          return titleChunk ? `${titleChunk} - ${this.$t('meta.titleTemplate')}` : this.$t('meta.titleTemplate')
        },
      }
    },

    extends: View,

    mixins: [
      LoadSections([
        'anonymous/sections/hero',
        // 'sections/hero-alt',
        'anonymous/sections/about-our-product',
        'sections/contact-us',
        'sections/info',
        // 'sections/theme-features',
        // 'sections/social-media',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
